@media (max-width: 576px) {
    .responsive-font {
        font-size: 0.5rem; /* Smaller font size for mobile phones */
    }
}

@media (max-width: 768px) {
    .responsive-font {
        font-size: 0.875rem; /* Slightly larger for tablets */
    }
}

@media (min-width: 769px) {
    .responsive-font {
        font-size: 1rem; /* Default size for larger screens */
    }
}
