/* Optional CSS */
.fixed-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #343a40;
    color: #ffffff;
    padding: 10px; /* Adjust padding as needed */
}
